'use client'

export const getURLSearchParams = (): URLSearchParams | undefined => {
    if (typeof window === 'undefined') {
        return undefined
    }
    return new URLSearchParams(window.location.search)
}

export const getApiUrlPrefix = (): string => {
    if (typeof window === 'undefined') {
        return process.env['API_URL_PREFIX']!
    }
    let apiUrlPrefix: string
    if (window.location.host.includes('circuit.ly')) {
        apiUrlPrefix = `${window.location.protocol}//api.${window.location.host}`
    } else {
        apiUrlPrefix = `${window.location.protocol}//${window.location.host}`
    }
    return apiUrlPrefix
}

export const getSiteUrlPrefix = (): string => {
    if (typeof window === 'undefined') {
        return process.env['SITE_URL_PREFIX']!
    }
    return `${window.location.protocol}//${window.location.host}`
}

export const getSessionCookieName = (): string => {
    if (typeof window === 'undefined') {
        return process.env['SESSION_COOKIE_NAME']!
    }
    let sessionCookieName: string
    if (window.location.host.includes('staging.circuit.ly')) {
        sessionCookieName = `X-Session-Staging-Token`
    } else if (window.location.host.includes('preflight.circuit.ly')) {
        sessionCookieName = `X-Session-Preflight-Token`
    } else if (window.location.host.includes('circuit.ly')) {
        sessionCookieName = `X-Session-Token`
    } else {
        sessionCookieName = `X-Session-Local-Token`
    }
    return sessionCookieName
}

export const getSessionToken = (): string | undefined => {
    const sessionCookieName = getSessionCookieName()
    const cookies = document.cookie.split(';')
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=')
        if (name.trim() === sessionCookieName) {
            return value.trim()
        }
    }
    return undefined
}

export const track = async (eventName: string, eventArgs?: { [key: string]: any }): Promise<void> => {
    const apiUrlPrefix = getApiUrlPrefix()
    await fetch(`${apiUrlPrefix}/v1/track`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            currentUrl: window.location.href,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            eventName,
            eventArgs,
        }),
    }).catch(err => {
        // eslint-disable-next-line no-console
        console.error(err)
    })
}

export const signup = async (
    userFields: {
        firstName: string
        lastName: string
        emailAddress: string
        password: string
    },
    options?: {
        referredBy?: string
        referredByOther?: string
        subscribeToNewsletter?: boolean
        returnTo?: string
    },
): Promise<{ redirectUrl?: string }> => {
    let url = `${getApiUrlPrefix()}/v1/user/signup`
    if (options?.returnTo) {
        url += `?return_to=${options.returnTo}`
    }
    let response: Response
    try {
        response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...userFields,
                referredBy: options?.referredBy,
                referredByOther: options?.referredByOther,
                subscribeToNewsletter: options?.subscribeToNewsletter,
            }),
        })
    } catch (err) {
        throw new Error(`failed to sign up`)
    }
    if (!response.ok) {
        if (response?.headers?.get('content-type')?.startsWith('application/json')) {
            const error = await response.json()
            throw new Error(error.message)
        } else {
            const message = await response.text()
            throw new Error(message)
        }
    }
    return response.json()
}

export type OAuthKinds = 'google' | 'gitlab' | 'github'

export const OAuthScopes: Record<OAuthKinds, string[]> = {
    google: ['openid', 'profile', 'email'],
    github: ['user:email'],
    gitlab: ['read_user', 'email'],
}

export const authorize = async (
    kind: OAuthKinds,
    scopes: string[],
    options?: {
        returnTo?: string
    },
): Promise<{ redirectUrl?: string }> => {
    let response: Response
    try {
        response = await fetch(`${getApiUrlPrefix()}/v1/authorize`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                kind,
                scopes,
                internalState: {
                    reason: 'signin',
                    redirect: options?.returnTo,
                },
            }),
        })
    } catch (err) {
        throw new Error(`failed to authorize with '${kind}'`)
    }
    if (!response.ok) {
        if (response?.headers?.get('content-type')?.startsWith('application/json')) {
            const error = await response.json()
            if (error.reason === 'AuthorizedScopesExists') {
                return { redirectUrl: undefined }
            } else {
                throw new Error(error.message)
            }
        } else {
            const message = await response.text()
            throw new Error(message)
        }
    } else {
        const { authorizationUrl } = await response.json()
        return { redirectUrl: authorizationUrl }
    }
}

export const signin = async (
    emailAddress: string,
    password: string,
    options?: { returnTo?: string },
): Promise<{ userId: string; redirectUrl?: string }> => {
    let url = `${getApiUrlPrefix()}/v1/user/signin`
    if (options?.returnTo) {
        url += `?return_to=${options.returnTo}`
    }
    let response: Response
    try {
        response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                emailAddress,
                password,
            }),
        })
    } catch (err) {
        throw new Error(`failed to sign in`)
    }
    if (!response.ok) {
        if (response?.headers?.get('content-type')?.startsWith('application/json')) {
            const error = await response.json()
            throw new Error(error.message)
        } else {
            const message = await response.text()
            throw new Error(message)
        }
    } else {
        const result = await response.json()
        return result
    }
}

export const forgotPassword = async (emailAddress: string): Promise<void> => {
    let response: Response
    try {
        response = await fetch(`${getApiUrlPrefix()}/v1/user/forgotpassword`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                emailAddress,
            }),
        })
    } catch (err) {
        throw new Error(`failed to send forgot password request`)
    }
    if (!response.ok) {
        if (response?.headers?.get('content-type')?.startsWith('application/json')) {
            const error = await response.json()
            throw new Error(error.message)
        } else {
            const message = await response.text()
            throw new Error(message)
        }
    }
}

export const resetPassword = async (token: string, password: string): Promise<{ redirectUrl?: string }> => {
    let response: Response
    try {
        response = await fetch(`${getApiUrlPrefix()}/v1/user/resetpassword`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password,
                token,
            }),
        })
    } catch (err) {
        throw new Error(`failed to reset password`)
    }
    if (!response.ok) {
        if (response?.headers?.get('content-type')?.startsWith('application/json')) {
            const error = await response.json()
            throw new Error(error.message)
        } else {
            const message = await response.text()
            throw new Error(message)
        }
    } else {
        const result = await response.json()
        return result
    }
}

export const updatePlan = async (userId: string, planId: string): Promise<void> => {
    let response: Response
    try {
        response = await fetch(`${getApiUrlPrefix()}/v1/user/${userId}/plan`, {
            method: 'PATCH',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                plan: planId,
            }),
        })
    } catch (err) {
        throw new Error(`failed to update user's plan`)
    }
    if (!response.ok) {
        if (response?.headers?.get('content-type')?.startsWith('application/json')) {
            const error = await response.json()
            throw new Error(error.message)
        } else {
            const message = await response.text()
            throw new Error(message)
        }
    }
}

export const updateUser = async ({
    subscribeToNewsletter,
    metadata,
}: {
    subscribeToNewsletter: boolean
    metadata: {
        background: string
        experience: string
        referredBy: string
        referredByOther?: string
    }
}): Promise<void> => {
    let response: Response
    try {
        response = await fetch(`${getApiUrlPrefix()}/v1/user`, {
            method: 'PATCH',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                metadata,
                subscribeToNewsletter,
            }),
        })
    } catch (err) {
        throw new Error(`failed to update user`)
    }
    if (!response.ok) {
        if (response?.headers?.get('content-type')?.startsWith('application/json')) {
            const error = await response.json()
            throw new Error(error.message)
        } else {
            const message = await response.text()
            throw new Error(message)
        }
    }
}
